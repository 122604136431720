import React from 'react';

import Footer from './Footer';

const Layout = ({ children }) => {

	return (
	<>

			<div className="skip-links">

				<a href="#skip-header-target">
					Jump to main content
				</a>

			</div>

			{children}

			<Footer />
	</>
	);
};

export default Layout;