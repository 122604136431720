import React from 'react';

import BackToTop from './BackToTop';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className='container wide'>
            
            <div className='display-flex justify-content-between align-items-center padding-y-3 border-top font-size-sm'>

                <div className='flex-column flex-row--md gap-1'>
                    <span>
                        &#169; {currentYear} - Avidano Digital.
                    </span>
                    <span>
                        All Rights Reserved.
                    </span> 
                </div>

                <div className='display-flex gap-3'> 
                    <BackToTop />
                </div>
            
            </div>
        
        </footer>
    );
};

export default Footer;